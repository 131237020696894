import React, {useEffect, useState} from 'react';
import {UpdateConnectAccount} from "../../services/users.service";

function ConnectAccount(props) {
     const [accountId ,setAccountId] = useState('')
     const [userId ,setUserId] = useState('')
    // function triggerApp() {
    //
    //     const searchParams = new URLSearchParams(window.location.search);
    //     const accountIdParam = searchParams.get('account_id');
    //     console.log(accountIdParam,'accountIdParam')
    //     console.log(typeof accountIdParam,'typeof accountIdParam')
    //     console.log(window,'window')
    //
    //     if (typeof window.flutter_inappwebview !== "undefined") {
    //         // window.Android.successHandler.postMessage(accountIdParam);
    //         window.flutter_inappwebview.callHandler('saveFormData', accountIdParam);
    //         // window.Android.onSuccess(accountIdParam);
    //     }
    //     if (typeof window.webkit !== "undefined") {
    //         window.webkit.messageHandlers.successHandler.postMessage(accountIdParam);
    //     }
    // }

    const handleContinueToApp = () => {
        // Call the PartyLux.postMessage method with the accountIdParam
        const searchParams = new URLSearchParams(window.location.search);
        const accountIdParam = searchParams.get('account_id');
        const userIdParam = searchParams.get('user_id');
        setAccountId(accountIdParam)
        setUserId(userIdParam)
    };
     const updateAccountId = () => {
         const searchParams = new URLSearchParams(window.location.search);
         const accountIdParam = searchParams.get('account_id');
         const userIdParam = searchParams.get('user_id');
         UpdateConnectAccount(userIdParam,accountIdParam).then((result) => {
             console.log("success:",result)
         }).catch((error) => {
             console.log("error:",error.response.data.msg)
         })
     }

    useEffect(()=>{
        handleContinueToApp()
        updateAccountId();
    },[])

    return (
        <div className="thank-you-page">
            <h1>Thank You!</h1>
            <p>Thanks a bunch for filling that out. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. Thanks for being you.</p>
            <button onClick={()=>window?.PartyLux?.postMessage(accountId)}>Continue to App</button>
            <p className="fineprint">Copyright ©{new Date().getFullYear()} | All Rights Reserved</p>
        </div>
    );
}

export default ConnectAccount;