import React, {useEffect, useState} from 'react';
import {Col, InputNumber, Row, Slider} from 'antd';
import debounce from "lodash.debounce";
import {FadeLoader} from "react-spinners";
import {yardsToMeter} from "../../utils/helpers";

function CustomMap({ disabled = false, radiusProp,setRadiusProp, position}) {
    const [map, setMap] = useState(null);
    const [circle, setCircle] = useState(null);
    const [initial, setInitial] = useState(true);
    const [radius, setRadius] = useState(50); // Default radius in yards
    // const [position, setPosition] = useState( { lat:0, lng: 0 }); // Default radius in meters
    const [centerMarker, setCenterMarker] = useState(null); // State to hold the center marker

    const loadMap = () => {
        const mapOptions = {
            center: position , // Initial center
            zoom: 15, // Initial zoom level
        };
        const map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
        setMap(map);

        // Create center marker
        const centerMarker = new window.google.maps.Marker({
            position: mapOptions.center,
            map: map,
            title: 'Pin',
            zIndex: window.google.maps.Marker.MAX_ZINDEX + 1
        });
        setCenterMarker(centerMarker);

        // Create circle
        const circle = new window.google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.5,
            strokeWeight: 2,
            fillColor: 'rgba(255,0,0,0.46)',
            fillOpacity: 0.35,
            map: map,
            center: mapOptions.center,
            radius: radius, // Initial radius
        });
        setCircle(circle);
    };

    // Initialize the map
    useEffect(() => {

         setTimeout(()=>{
             if (!window.google) {
                 const script = document.createElement('script');
                 script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE}`;
                 script.onload = loadMap;
                 document.head.appendChild(script);
             } else {
                 loadMap();
             }

         },3000)

    }, [position ]);

    const debouncedRequest = debounce(
        (value) => {
            setRadiusProp(value);
        },
        1000
    );
    // Update circle radius when slider changes
    const handleRadiusChange = (value) => {
        setRadius(value);
        debouncedRequest(yardsToMeter(value))

        if (circle) {
            circle.setRadius(yardsToMeter(value));
        }
    };

    useEffect(() => {
        setTimeout(()=>{
        if (initial && radiusProp !== radius && circle) {
            setRadius(radiusProp);
            circle.setRadius(radiusProp);
            setInitial(false);
        }
        },3000)
    }, [radiusProp, circle, initial]);



    return (
        <div>
            <Row>
                <Col span={12} className={'mb-2'}>
                    <span className={'radius-title'}>Distance (yards)</span>
                </Col>
            </Row>
            <Row>
                <Col span={12} className={'mb-2'}>
                    <Slider
                        min={1}
                        max={1000}
                        trackStyle={{ background: 'linear-gradient(90deg, rgb(191, 146, 226) 25%, rgb(139, 77, 198) 75%)' }}
                        railStyle={{ backgroundColor: '#1c2222' }}
                        onChange={handleRadiusChange}
                        disabled={disabled}
                        value={radius}
                    />
                </Col>
                <Col span={4}>
                    <InputNumber
                        min={10}
                        max={1000}
                        style={{
                            margin: '0 16px',
                        }}
                        disabled={disabled}
                        value={parseInt(radius)}
                        onChange={handleRadiusChange}
                    />
                </Col>
            </Row>
            <div className="map-wrapper">
                {initial &&   <div className="map-loader">
                    <FadeLoader color={"rgb(139, 77, 198)"} height={10} />
                </div>
                }

                <div id="map" style={{ height: '400px', width: '100%' }}>

                </div>
            </div>

        </div>
    );
}

export default CustomMap;
